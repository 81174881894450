@tailwind base;
@tailwind components;

.code_1 {
  @apply bg-gray-800 text-white px-5 py-5 mt-2 mb-2 w-min;
}

button {
  @apply m-2 bg-onef8 text-white rounded-md px-4 py-2 transition duration-500 select-none cursor-pointer text-left;
}

button:hover {
  @apply bg-darkgray;
}

button:focus {
  @apply outline-none;
}

.box-shadow-bottom {
  box-shadow: -1px 3px 5px -5px #000000;
}

.fukidashi {
  display: none;
  width: 300px;
  height: 180px;
  position: absolute;
  top: -8.5rem;
  left: -6.8rem;
  padding: 16px;
  border-radius: 5px;
  background: #33cc99;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
  opacity: 0.95;
}

#test:hover #fukidashi {
  display: block;
}

#root {
  h1 {
    @apply font-bold leading-10;
  }
  h2 {
    @apply text-4xl my-6 border-b-4 pb-2 border-green-600 pr-4 pl-2 pt-4 inline-block;
  }
  h3 {
    @apply text-3xl my-6 border-b pb-2 border-gray-500 px-2 inline-block;
  }
  p {
    @apply py-1 text-lg;
  }
  a {
    @apply text-blue-600;
  }
  li {
    @apply text-lg
  }
  b {
    @apply text-xl
  }
}

@tailwind utilities;
